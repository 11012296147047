
































































import {Component, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/payroll/PayrollService";
import IncomeTaxReturnDocumentDTO, {FEDERAL_CATEGORY} from "@/dto/payroll/IncomeTaxReturnDocumentDTO";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";
import Workspaces from "@/state/Workspaces";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import UploadIndividualIncomeTaxReturn from "@/components/payroll/individual/UploadIndividualIncomeTaxReturn.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		}
	},
	components: {PortalSelect, FileUpload}
})
export default class FederalIncomeTaxReturn extends Vue {

	private message = "";

	private successful = true;

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void;

	@AppModule.Action
	private stopLoading!: () => void;

	@Auth.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	private years: Array<string> = [];

	private filter: { year: string | null } = {year: null};

	private documents: Array<IncomeTaxReturnDocumentDTO> = [];

	openUploadModal() {
		this.$modal.show(
			UploadIndividualIncomeTaxReturn,
			{
				category: "FEDERAL",
				callback: (year: string) => this.loadYearsAndDocuments(year)
			}, {
				height: "auto"
			}
		)
	}

	mounted() {
		this.loadYearsAndDocuments();
	}

	personId() {
		return Number.parseInt(this.$route.params.personId, 10);
	}

	loadYearsAndDocuments(year?: string) {
		this.loadYears().then(() => {
			console.log(year)
			this.filter.year = year ? year : this.filter.year
			this.loadDocuments()
		});
	}

	loadYears() {
		this.message = "";
		this.startLoading();
		return PayrollService.getYearsForIncomeTax(Workspaces.getCurrent.id, FEDERAL_CATEGORY).then(
			response => {
				this.years = response.data;
				this.filter.year = response.data.sort().reverse()[0];
				this.successful = true;
				this.stopLoading();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		)
	}

	public loadDocuments(): void {
		this.message = "";
		this.startLoading();
		PayrollService.getIncomeTaxDocuments(Workspaces.getCurrent.id, this.filter.year as string, FEDERAL_CATEGORY).then(
			response => {
				this.documents = response.data;
				this.successful = true;
				this.stopLoading();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		)
	}

	onDocumentClick(document: IncomeTaxReturnDocumentDTO) {
		if (this.hasAdministrativePrivileges) {
			this.showActions(document);
		} else {
			Vue.prototype.$docUtils.download(document.file.id);
		}
	}

	showActions(document: IncomeTaxReturnDocumentDTO) {
		this.$modal.show(
			PayrollDocumentActionModal,
			{
				onDelete: () => this.deleteDocument(document),
				onDownload: () => Vue.prototype.$docUtils.download(document.file.id)
			},
			{width: "50%", height: "auto"}
		);
	}

	deleteDocument(document: IncomeTaxReturnDocumentDTO) {
		this.message = "";
		this.startLoading();
		PayrollService.deleteDocument(document.id, 'INCOME_TAX_RETURN').then(
			response => {
				this.message = "Deleted";
				this.successful = true;
				this.stopLoading();
				this.loadDocuments();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		);
	}

}
